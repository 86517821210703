<template>
  <div id="quotations-audits">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <h5>Nombre Proyecto</h5>
            <b-row>
              <b-col xl="3" sm="12">
                <b-form-input id="name"
                  v-model="nameProject"
                  name="name"
                  disabled />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <projects-audits-list :audits="audits"></projects-audits-list>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import ProjectsAuditsList from './components/ProjectsAuditsList'

  export default{
    name: 'ProjectsAuditsView',
    components: {ProjectsAuditsList},
    props: ['audits'],
    data() {
      return {
      }
    },
    computed: {
      nameProject () {
        return this.audits ? `${this.audits.name}` : ''
      },
    },
  }
</script>